<template>
    <div class="w-full">
        <div class="flex flex-wrap">
            <div
                @click="openLightbox()"
                class="p-1 cursor-pointer"
            >
                <img
                    :src="thumbnail"
                    :alt="ImageName"
                    class="object-cover"
                />
            </div>
        </div>

        <div
            v-if="showLightbox"
            @click="closeLightbox"
            class="fixed top-0 left-0 right-0 bottom-0 bg-kvpBlack bg-opacity-80 z-50 flex flex-col md:flex-row items-center justify-center w-full h-full"
        >
                <img
                    :src="image"
                    :alt="ImageName"
                    class="w-auto h-auto max-w-full max-h-full md:p-24"
                    loading="lazy"
            />
            <button
                class="absolute top-0 right-0 mt-2 mr-2"
                @click="closeLightbox"
            >
                <svg class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="white">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Lightbox",
    data() {
        return {
            showLightbox: false,
        };
    },
    props: {
        image: {
            type: String,
            required: true
        },
    },
    methods: {
        openLightbox() {
            this.showLightbox = true;
        },
        closeLightbox() {
            this.showLightbox = false;
        }
    },
    computed: {
        ImageName(){
            let parts = this.image.split("/");
            return parts[parts.length - 1].split(".")[0]
        },
        thumbnail() {
        //     Strip the extension off the image name and add the thumbnail suffix
            let parts = this.image.split("/");
            let extentsion = parts[parts.length - 1].split(".")[1];
            return this.image.replace(/\.[^/.]+$/, "") + "_thumb." + extentsion;
        }
    },
};
</script>

<style scoped>

.object-cover {
    object-fit: cover;
    width: 100%;
    height: auto;
}

</style>
